import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";
import LogIn from "./pages/auth/login.js";
import SignUp from "./pages/auth/signup";
import ForgotPassword from "./pages/auth/forgotPassword";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./configs/keys";
import Home from "./pages/home/home";
import Product from "./pages/product/product";
import Cart from "./pages/cart/cart";
import Category from "./pages/category/category";
import { StatusBar } from "react-native";
function App() {
  <StatusBar backgroundColor="#f7efdc" translucent={true} />;
  useEffect(() => {
    initializeApp(firebaseConfig);
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/login" element={<LogIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/product/:titleSlug" element={<Product />} />
        <Route exact path="/collections/:categorySlug" element={<Category />} />
        <Route exact path="/cart" element={<Cart />} />
      </Switch>
    </Router>
  );
}

export default App;
