import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router'
import { getCateogoryWithSlug } from '../../helpers/api'
import Navbar from '../home/navbar'
import Footer from '../home/footer'
import './category.css'
import '../home/home.css'
import { ProductCard } from '../home/home'

function Category() {
  const { categorySlug } = useParams()
  const [categoryData, setCategoryData] = useState()
  const [productsList, setProductsList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCategory()
  }, [])

  const getCategory = async () => {
		try {
      let resp = await getCateogoryWithSlug(categorySlug)
      console.log(resp.data)
			setCategoryData(resp.data["category"])
      setProductsList(resp.data["products"])
      setLoading(false)
    } catch (e) {
      console.error(e.response.data);
    } 
	}

  return (
    <div className = {isMobile ? "mobile" : "web"}>
      <Navbar />
      <div style = {{ minHeight: 'calc(100vh - 70px)' }}>
      {
        !loading &&
        <>
          <div className="banner">
            <img src = {categoryData["banner"]["image"]} alt="banner" />
          </div>
          <div style = {{ display: 'flex', justifyContent: 'center' }}>
            <div style = {{ maxWidth: '100%' }}>
              <div className="category-grid">
                {productsList.map((data, i) => (
                  <div className="item">
                    <ProductCard
                      itemId={i} // NOTE: itemId is required for track items
                      key={i}
                      data = {data}
                    />
                  </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      }
      </div>
      <Footer />
    </div>
  )
}

export default Category