import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getMultipleProductsInCart } from '../../helpers/api';
import Navbar from '../home/navbar';
import Footer from '../home/footer'
import DeleteIcon from '../../assets/cart/delete.png'
import './cart.css'
import { addQuantityToItemInCart, removeItemFromCart, subtractQuantityFromItemInCart } from '../../actions/cart';

function Cart() {

	const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
	const cartItems = cart.cartItems
	const [itemsList, setItemsList] = useState([])
	const [totalAmount, setTotalAmount] = useState(0)

	useEffect(() => {
		getCartItemsDetails()
	}, [])

	const getCartItemsDetails = async () => {
		try {
			let resp = await getMultipleProductsInCart(cartItems.map(v => v["productId"]))
			let itemsList = resp.data
			let totalAmount = 0;
			cart.cartItems.forEach(v => {
				let item = itemsList.find(val => val["_id"] == v["productId"])
				if (item)
					totalAmount += (item["discountPrice"] || item["originalPrice"])*v["quantity"]
			})
			setTotalAmount(totalAmount)
			setItemsList(itemsList)
		} catch(e) {
			console.error(e);
		}
	}

	const subtractQuantity = async (productId) => {
		let index = cartItems.findIndex(v => v["productId"] == productId)
		if (cartItems[index]["quantity"] == 1)
			removeItem(productId)
		else
			dispatch(subtractQuantityFromItemInCart(productId))
	}

	const addQuantity = async (productId) => {
		dispatch(addQuantityToItemInCart(productId))
	}

	const removeItem = async (productId) => {
		let index = itemsList.findIndex(v => v["_id"] == productId)
		let newItemsList = JSON.parse(JSON.stringify(itemsList))
		newItemsList.splice(index, 1)
		setItemsList(newItemsList)
		dispatch(removeItemFromCart(productId))
	}

	useEffect(() => {
		localStorage.setItem("cart", JSON.stringify(cart))
		let totalAmount = 0;
		cart.cartItems.forEach(v => {
			let item = itemsList.find(val => val["_id"] == v["productId"])
			if (item)
			totalAmount += (item["discountPrice"] || item["originalPrice"])*v["quantity"]
		})
		setTotalAmount(totalAmount)
	}, [cart])

	return (
		<div className={isMobile ? "mobile" : "web"}>
			<Navbar />
			<div className="cart-content">
				<div className="header">
					<div className="my-cart">My Cart</div>
				</div>
				<div className="cart-items">
					{
						itemsList.map((v) => 
							<div className="cart-item">
								{
									isMobile ?
									<>
										<img className="product-image" src={v["thumbnailImage"]} alt="Product" />
										<div className="title-details">
											<div className="title">{v["title"]}</div>
											<div className="quantity-container">
												<div className="action clickable" onClick = {() => subtractQuantity(v["_id"])}>-</div>
												<div className="quantity">{cartItems.find(item => item["productId"] == v["_id"])["quantity"]}</div>
												<div className="action clickable" onClick = {() => addQuantity(v["_id"])}>+</div>
											</div>
											<div className="price-container">
												<div className="price">₹{(v["discountPrice"] || v["originalPrice"])*(cartItems.find(item => item["productId"] == v["_id"])["quantity"])}</div>	
												<img className="clickable delete-item" src = {DeleteIcon} alt="Delete" onClick = {() => removeItem(v["_id"])} />
											</div>
										</div>
									</>:
									<>
										<img className="clickable delete-item" src = {DeleteIcon} alt="Delete" onClick = {() => removeItem(v["_id"])} />
										<img className="product-image" src={v["thumbnailImage"]} alt="Product" />
										<div className="title-details">
											<div className="title">{v["title"]}</div>
											<div className="button">Free Size</div>
										</div>
										<div className="price-container">
											<div className="price">₹{v["discountPrice"] || v["originalPrice"]}</div>
											<div className="quantity-container">
												<div className="action clickable" onClick = {() => subtractQuantity(v["_id"])}>-</div>
												<div className="quantity">{cartItems.find(item => item["productId"] == v["_id"])["quantity"]}</div>
												<div className="action clickable" onClick = {() => addQuantity(v["_id"])}>+</div>
											</div>
											<div className="price">₹{(v["discountPrice"] || v["originalPrice"])*(cartItems.find(item => item["productId"] == v["_id"])["quantity"])}</div>	
										</div>
									</>
								}
							</div>
						)
					}
				</div>
				<div style = {{ display: 'flex', justifyContent: 'center' }}>
					<div className="checkout-container">
						<div className="total-container">
							<div className="total-amount">Total: ₹{totalAmount}</div>
							<div className="total-quantity">Total Quantity: {cart.cartCount}</div>
						</div>
						<div className="checkout-button clickable">
							<span>{isMobile ? 'CHECKOUT' : 'PROCEED TO CHECKOUT'}</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Cart;