import React, { useState } from "react";
import "./style/auth.css";
import loginImage from "../../assets/login/login-image.png";
import google from "../../assets/login/google.png";
import { Link } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { message } from "antd";
import { GoogleAuthProvider } from "firebase/auth";
import { signUpUser } from "../../helpers/api";
import HomeIcon from "@mui/icons-material/Home";

function SignUp() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const validateData = async () => {
    if (password !== confirmPassword) {
      message.error("Passwords do not match");
      return false;
    }
    return true;
  };

  const signUpWithEmail = async () => {
    if (!(await validateData())) return;
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("Signed Up");
      let loginResp = await signUpUser({
        email,
        uid: userCredential.user.uid,
      });
      localStorage.setItem("verify", loginResp.data["token"]);
    } catch (e) {
      if (e.code == "auth/invalid-email")
        message.error("Please enter a valid email");
      if (e.code == "auth/email-already-in-use")
        message.error("You've already signed up! Please log in.");
      if (e.code == "auth/weak-password")
        message.error("Password should be at least have 8 characters");
      console.log(e.code);
    }
  };

  const signInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      console.log("Logged In");
      let loginResp = await signUpUser({
        email,
        uid: userCredential.user.uid,
      });
      localStorage.setItem("verify", loginResp.data["token"]);
    } catch (e) {
      message.error("Some error occurred! Please try again later.");
      console.error(e);
    }
  };

  return (
    <div className="flex ">
      <div>
        <img className="login-image" src={loginImage} alt="login_image" />
      </div>
      <div className="login-container">
        <div className="login-input-container">
          <div className="login-title">
            Welcome to
            <br />
            <span className="monkmerch">MonkMerch</span>
            <br />
            <span className="tagline">From the soul of the mountains</span>
          </div>
          <input
            className="margin-top-26 input-field"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="margin-top-12 input-field"
            placeholder="Enter your password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="margin-top-12 input-field"
            placeholder="Confirm your password"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div
            className="sign-in-button clickable margin-top-12"
            onClick={() => signUpWithEmail()}
          >
            Sign Up
          </div>
          <Link className="new-user clickable" to="/login">
            Already a user? <span className="signup">Sign In Here</span>
          </Link>
          <span className="or">Or</span> <br />
          <div
            className="sign-in-with-google clickable"
            onClick={() => signInWithGoogle()}
          >
            <img src={google} alt="google" />
            Sign In With Google
          </div>
        </div>
        <Link to="/">
          <div className="back_home clickable">
            <HomeIcon id="homeIcon" />
            Back to <span className="home">Home</span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SignUp;
