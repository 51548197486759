import React, { useState } from "react";
import "./style/auth.css";
import loginImage from "../../assets/login/login-image.png";
import back from "../../assets/login/back.svg";
import { Link } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { message } from "antd";

function ForgotPassword() {
  const [email, setEmail] = useState();

  const sendResetEmail = async () => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      message.success("Password reset email sent");
    } catch (e) {
      if (e.code == "auth/invalid-email")
        message.error("Please enter a valid email");
      console.error(e);
    }
  };

  return (
    <div className="flex">
      <div>
        <img className="login-image" src={loginImage} alt="login_image" />
      </div>
      <div className="login-container">
        <div className="login-input-container">
          <div className="login-title">
            Forgot your
            <br />
            <span className="monkmerch">Password?</span>
            <br />
            <span className="tagline">
              We’ll send you a verification code to your email address
            </span>
          </div>
          <input
            className="margin-top-26 input-field"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div
            className="sign-in-button clickable margin-top-12"
            onClick={() => sendResetEmail()}
          >
            Send Password Reset Email
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link className="new-user clickable" to="/login">
              <span>
                <img src={back} alt="back" style={{ maxHeight: 7 }} />
              </span>{" "}
              Back to <span className="signup">Login</span>
            </Link>
          </div>
          {/* <span className="or">Or</span> <br/> */}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
