import React from 'react';
import "./home.css"
import logo from '../../assets/common/logo.svg'
import mobileLogo from '../../assets/common/mobileLogo.png'
import cartIcon from '../../assets/common/cart.svg'
import profileIcon from '../../assets/common/profile.svg'
import searchIcon from '../../assets/common/search.svg'
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

function Navbar() {

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
	const cartCount = cart.cartCount

  return (
    <div className="navbar">
      <div className="half-screen">
      <Link to='/'><img src={isMobile ? mobileLogo : logo} alt="logo" className="logo clickable" /></Link>
      </div>
      <div className="half-screen menu-items">
        <div className="search-box">
          <img src={searchIcon} alt="search" style = {{ marginRight: 12 }} />  
          {/* <input /> */}
        </div>
        <Link to='/login'><img src={profileIcon} alt="profile" className="menu-icons clickable" style = {{ marginRight: 18 }} />  </Link>
        <Link to='/cart'>
          <div className="cart">
            <img src={cartIcon} alt="cart" className="menu-icons clickable" />
            <div className="cart-number" style = {{ visibility: cartCount == 0 && 'hidden' }}>{cartCount}</div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Navbar;