import React, { useState } from "react";
import "./style/auth.css";
import loginImage from "../../assets/login/login-image.png";
import google from "../../assets/login/google.png";
import { Link } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { message } from "antd";
import { GoogleAuthProvider } from "firebase/auth";
import { loginUser } from "../../helpers/api";
import HomeIcon from "@mui/icons-material/Home";

function LogIn() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const logInWithEmail = async () => {
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      let loginResp = await loginUser({
        email,
        uid: userCredential.user.uid,
      });
      localStorage.setItem("verify", loginResp.data["token"]);
    } catch (e) {
      if (e.code == "auth/invalid-email")
        message.error("Please enter a valid email");
      if (e.code == "auth/wrong-password")
        message.error(
          "Wrong Email ID/Password. Please enter the correct password"
        );
      if (e.code == "auth/user-not-found")
        message.error(
          "This email ID is not registered with us! Please sign up first"
        );
      console.log(e.code);
    }
  };

  const signInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      console.log("Logged In");
      let loginResp = await loginUser({
        email,
        uid: userCredential.user.uid,
      });
      localStorage.setItem("verify", loginResp.data["token"]);
    } catch (e) {
      message.error("Some error occurred! Please try again later.");
      console.error(e);
    }
  };

  return (
    <div className="flex ">
      <div>
        <img className="login-image" src={loginImage} alt="login_image" />
      </div>
      <div className="login-container">
        <div className="login-input-container">
          <div className="login-title">
            Sign In to
            <br />
            <span className="monkmerch">MonkMerch</span>
            <br />
            <span className="tagline">From the soul of the mountains</span>
          </div>
          <input
            className="margin-top-26 input-field"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="margin-top-12 input-field"
            placeholder="Enter your password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Link
            className="forgot-password margin-top-12 clickable"
            to="/forgot-password"
          >
            Forgot your password?
          </Link>
          <br />
          <div
            className="sign-in-button clickable"
            onClick={(e) => logInWithEmail()}
          >
            Sign In
          </div>
          <Link className="new-user clickable" to="/signup">
            New User? <span className="signup">Sign Up Here</span>
          </Link>
          <span className="or">Or</span> <br />
          <div
            className="sign-in-with-google clickable"
            onClick={() => signInWithGoogle()}
          >
            <img src={google} alt="google" />
            Sign In With Google
          </div>
        </div>
        <Link to="/">
          {" "}
          <div className="back_home clickable">
            <HomeIcon id="homeIcon" />
            Back to <span className="home">Home</span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default LogIn;
