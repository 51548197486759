import {
	ADD_NEW_ITEM_TO_CART,
	REMOVE_ITEM_FROM_CART,
	ADD_QUANTITY,
	REMOVE_QUANTITY,
	CLEAR_CART
} from '../redux-constants'

const initialData = {
	cartItems: [],
	cartCount: 0
}

const localStorageData = JSON.parse(localStorage.getItem("cart"));

const initialState = localStorageData || initialData

export function cart(state = initialState, action) {
	let cartIndex;
	let newArr;
	switch(action.type) {
		case ADD_NEW_ITEM_TO_CART:
			return {
				cartItems: [...state.cartItems, {productId: action.payload.productId, quantity: 1}],
				cartCount: state.cartCount + 1
			}
		case REMOVE_ITEM_FROM_CART:
			cartIndex = state.cartItems.findIndex(v => v["productId"] == action.payload.productId)
			newArr = JSON.parse(JSON.stringify(state.cartItems))
			let itemQuantity = newArr[cartIndex]["quantity"]
			newArr.splice(cartIndex, 1)
			return {
				cartItems: newArr,
				cartCount: state.cartCount - itemQuantity
			}
		case ADD_QUANTITY:
			cartIndex = state.cartItems.findIndex(v => v["productId"] == action.payload.productId)
			newArr = JSON.parse(JSON.stringify(state.cartItems))
			newArr[cartIndex]["quantity"]++
			return {
				cartItems: newArr,
				cartCount: state.cartCount + 1
			}
		case REMOVE_QUANTITY:
			cartIndex = state.cartItems.findIndex(v => v["productId"] == action.payload.productId)
			newArr = JSON.parse(JSON.stringify(state.cartItems))
			newArr[cartIndex]["quantity"]--
			return {
				cartItems: newArr,
				cartCount: state.cartCount - 1
			}
		case	CLEAR_CART:
			return initialState
		default:
			return state
	}
}