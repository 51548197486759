import { ADD_NEW_ITEM_TO_CART, ADD_QUANTITY, REMOVE_ITEM_FROM_CART, REMOVE_QUANTITY } from "../redux-constants"

export const addItemToCart = (productId) => {
	return async function (dispatch) {
		dispatch({
			type: ADD_NEW_ITEM_TO_CART,
			payload: { productId }
		})
	}
}

export const addQuantityToItemInCart = (productId) => {
	return async function (dispatch) {
		dispatch({
			type: ADD_QUANTITY,
			payload: { productId }
		})
	}
}

export const subtractQuantityFromItemInCart = (productId) => {
	return async function (dispatch) {
		dispatch({
			type: REMOVE_QUANTITY,
			payload: { productId }
		})
	}
}

export const removeItemFromCart = (productId) => {
	return async function (dispatch) {
		dispatch({
			type: REMOVE_ITEM_FROM_CART,
			payload: { productId }
		})
	}
}