import React, { useEffect, useState } from "react";
import { getHome } from "../../helpers/api";
import Navbar from "./navbar";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import leftArrow from "../../assets/home/leftArrow.svg";
import rightArrow from "../../assets/home/rightArrow.svg";
import "./home.css";
import useDrag from "../../helpers/utils/useDrag";
import Footer from "./footer";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

function Home() {
  const [sections, setSections] = useState([]);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    if (history.action === "POP")
      console.log("Back button used. Not running stuff");
    else getAllHome();
  }, []);

  const getAllHome = async () => {
    try {
      let resp = await getHome();
      setSections(resp.data.sections);
      setBanners(resp.data.banners);
      setLoading(false);
      console.log(resp.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={isMobile ? "mobile" : "web"}>
      <Navbar />
      {!loading && (
        <div className="banner-container">
          <img src={banners[0]["image"]} alt="banner" />
        </div>
      )}
      {!loading && (
        <div>
          {sections.map((v) =>
            v["sectionType"] == 1 ? (
              <div
                className="section1"
                style={{ backgroundColor: v["backgroundColor"] }}
              >
                {!isMobile ? (
                  <>
                    <img
                      className="coverImage"
                      src={v["coverImage"]}
                      alt="Cover Img"
                    />
                    <div className="data">
                      <div className="title">{v["title"]}</div>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: v["description"] }}
                      />
                      <div style={{ height: 65 }} />
                      <div
                        style={{
                          width: "calc(94vw - 512px - 85px)",
                          maxWidth: "100%",
                        }}
                      >
                        <ScrollMenu
                        // LeftArrow={LeftArrow}
                        // RightArrow={RightArrow}
                        >
                          {[...v["products"], ...v["products"]].map(
                            (data, i) => (
                              <ProductCard
                                itemId={i} // NOTE: itemId is required for track items
                                key={i}
                                data={data}
                              />
                            )
                          )}
                        </ScrollMenu>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="data">
                      <div className="title">{v["title"]}</div>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: v["description"] }}
                      />
                      <img
                        className="coverImage"
                        src={v["coverImage"]}
                        alt="Cover Img"
                      />
                    </div>
                    <div style={{ height: 65 }} />
                    <div
                      style={
                        !isMobile
                          ? {
                              width: "calc(94vw - 512px - 85px)",
                              maxWidth: "100%",
                            }
                          : { padding: "0px 20px" }
                      }
                    >
                      <ScrollMenu
                      //  LeftArrow={LeftArrow} RightArrow={RightArrow}
                      >
                        {[...v["products"], ...v["products"]].map((data, i) => (
                          <ProductCard
                            itemId={i} // NOTE: itemId is required for track items
                            key={i}
                            data={data}
                          />
                        ))}
                      </ScrollMenu>
                    </div>
                  </>
                )}
              </div>
            ) : v["sectionType"] == 2 ? (
              <div
                className="section2"
                style={{ backgroundImage: `url(${v["coverImage"]})` }}
              >
                <div
                  className="title"
                  dangerouslySetInnerHTML={{ __html: v["title"] }}
                />
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: v["description"] }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ maxWidth: "100%" }}>
                    <ScrollMenu
                    // LeftArrow={LeftArrow} RightArrow={RightArrow}
                    >
                      {v["products"].map((data, i) => (
                        <ProductCard
                          itemId={i} // NOTE: itemId is required for track items
                          key={i}
                          data={data}
                        />
                      ))}
                    </ScrollMenu>
                  </div>
                </div>
              </div>
            ) : v["sectionType"] == 3 ? (
              <div
                className="section3"
                style={{ backgroundColor: v["backgroundColor"] }}
              >
                <div
                  className="title"
                  dangerouslySetInnerHTML={{ __html: v["title"] }}
                />
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: v["description"] }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ maxWidth: "80vw" }}>
                    <div className="grid">
                      {v["products"].map((data, i) => (
                        <div className="item">
                          <ProductCard
                            itemId={i} // NOTE: itemId is required for track items
                            key={i}
                            data={data}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}

export function ProductCard({ data }) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <Link to={`/product/${data["titleSlug"]}`}>
      <div className="product clickable">
        <img src={data["thumbnailImage"]} className="img" alt="Product Img" />
        <div className="contents">
          <div className="title">{data["title"]}</div>
          <div className="price">{data["originalPrice"]}</div>
        </div>
      </div>
    </Link>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <img
      className="clickable"
      style={{
        position: "absolute",
        top: isMobile ? 84 : 150,
        zIndex: 2500,
        left: 0,
        width: isFirstItemVisible ? 0 : 40,
      }}
      onClick={() => scrollPrev()}
      alt="left arrow"
      src={leftArrow}
    />
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <img
      className="clickable"
      style={{
        position: "absolute",
        top: isMobile ? 84 : 150,
        zIndex: 2500,
        right: 0,
        width: isLastItemVisible ? 0 : 40,
      }}
      onClick={() => scrollNext()}
      alt="right arrow"
      src={rightArrow}
    />
  );
}

export default Home;
