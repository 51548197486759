import axios from "axios";

const api = axios.create({ baseURL: "https://monkmerch-backend.onrender.com/" });
// const api = axios.create({ baseURL: "http://localhost:8081/" });

// interceptor (To pass auth token in every request)
api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("verify");
    config.headers = { token: token };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Verificiation And Authentication Process Apis
export const loginUser = (data) => api.post('auth/login/user', data);
export const signUpUser = (data) => api.post('auth/signup/user', data);

//Home APIs
export const getHome = () => api.post('home/all')

//Product APIs
export const getProductWithSlug = (slug) => api.get(`product/${slug}`)
export const getCateogoryWithSlug = (slug) => api.get(`product/category/${slug}`)

//Cart APIs
export const getMultipleProductsInCart = (data) => api.post('product/cart-items', data)
