import React, { useEffect, useState } from 'react';
import "./product.css"
import Navbar from '../home/navbar'
import Footer from '../home/footer'
import { useParams } from 'react-router';
import { getProductWithSlug } from '../../helpers/api';
import bag from '../../assets/common/bag.svg'
import wishlist from '../../assets/common/wishlist.svg'
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart, addQuantityToItemInCart } from '../../actions/cart';
import { message } from 'antd';

// let resp = await getMultipleProductsInCart(cartItems)
// 		let itemsList = resp.data

function Product() {

	let { titleSlug } = useParams()
	const [productData, setProductData] = useState()
	const [loading, setLoading] = useState(true)
	const [selectedImageIndex, setSelectedImageIndex] = useState(0)
	const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

	useEffect(() => {
		getProduct();
	}, [])

	const getProduct = async () => {
		try {
      let resp = await getProductWithSlug(titleSlug)
			setProductData(resp.data)
      setLoading(false)
    } catch (e) {
      console.error(e.response.data);
    } 
	}

	const addToCart = async () => {
		let index = cart.cartItems.findIndex(v => v["productId"] == productData._id)
		if (index == -1)
			dispatch(addItemToCart(productData._id))
		else
			dispatch(addQuantityToItemInCart(productData._id))
		message.success({
			content: 'Your item has ben added to the cart, please go to cart and continue checkout',
			// className: 'message',
			// duration: 10
		})
	}

	useEffect(() => {
		localStorage.setItem("cart", JSON.stringify(cart))
	}, [cart])

  return (
		<div className={isMobile ? "mobile" : "web"}>
			<Navbar />
			{
				!loading &&
				<div className="content">
					<div className="image-section">
						<img className="main-image" src={productData["images"][selectedImageIndex]} alt="Main Img" />
						<div className="grid">
							{
								productData["images"].map((img, index) => <img onClick = {() => setSelectedImageIndex(index)} className={("thumb-image clickable ") + (selectedImageIndex == index && "selected")} src={img} alt = "Alt Img" />)
							}
						</div>
					</div>
					<div className="content-section">
						{
							isMobile ? 
							<div>
								<div className = "pricing">
										<span className="final-price">₹{productData["discountPrice"] == null ? productData["originalPrice"] : productData["discountPrice"]}</span>
										{
											<>
												<span className="line-through-price">₹{productData["originalPrice"]}</span>
												<span className="percent-off">{Math.round(((productData["originalPrice"] - productData["discountPrice"])/productData["originalPrice"])*100)}% Off</span>
											</>
										}
									</div>
								<div className="title">{productData["title"]}</div>
								<div className="actions">
									<div className="add-to-bag-button clickable" onClick={() => addToCart()}>
										<span>ADD TO BAG</span>
										<img src={bag} alt="bag" />
									</div>
									<div className="wishlist-button clickable">
										<span>WISHLIST</span>
										<img src={wishlist} alt="wishlist" />
									</div>
								</div>
								<div className="divider" />
								<div className="pincode-text">Enter Pincode to check delivery</div>
								<div className="pincode-check-section">
									<div className="input">
									</div>
									<div className="check-button clickable">
										<span>Check</span>
									</div>
									<div className="expected-delivery">
										<div className="text">Expected delivery:</div>
										<div className="date">24 Nov</div>
									</div>
								</div>
								<div className="divider" />
								<div className="description" dangerouslySetInnerHTML={{ __html: productData["description"] }} />
							</div>:
							<div>
								<div className="title">{productData["title"]}</div>
								<div className="description" dangerouslySetInnerHTML={{ __html: productData["description"] }} />
								<div className = "pricing">
									<span className="final-price">₹{productData["discountPrice"] == null ? productData["originalPrice"] : productData["discountPrice"]}</span>
									{
										<>
											<span className="line-through-price">₹{productData["originalPrice"]}</span>
											<span className="percent-off">{Math.round(((productData["originalPrice"] - productData["discountPrice"])/productData["originalPrice"])*100)}% Off</span>
										</>
									}
								</div>
								<div className="actions">
									<div className="add-to-bag-button clickable" onClick = {() => addToCart()}>
										<span>ADD TO BAG</span>
										<img src={bag} alt="bag" />
									</div>
									<div className="wishlist-button clickable">
										<span>WISHLIST</span>
										<img src={wishlist} alt="wishlist" />
									</div>
								</div>
								<div className="divider" />
								<div className="pincode-text">Enter Pincode to check delivery</div>
								<div className="pincode-check-section">
									<div className="input">
									</div>
									<div className="check-button clickable">
										<span>Check</span>
									</div>
									<div className="expected-delivery">
										<div className="text">Expected delivery:</div>
										<div className="date">24 Nov</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			}
			<Footer />
		</div>
	)
}

export default Product