import React from 'react';
import footerLogo from '../../assets/common/footer-logo.svg'
import "./home.css"
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
	return(
		<div className="footer">
			<img src={footerLogo} style = {{ height: 72 }} alt="footer logo" />
			<div className="footer-tag">From the soul of the mountains</div>
			<div className="grid">
				<div className="item">
					<div className="menu-title">
						Quick Links
					</div>
					<Link to="/collections/hoopers"><div className="menu-item clickable">Hoppers</div></Link>
					<div className="menu-item clickable">Jackets</div>
					<div className="menu-item clickable">Sweatshirts</div>
					<div className="menu-item clickable">Shawls</div>
					<div className="menu-item clickable">Bags</div>
				</div>
				<div className="item">
					<div className="menu-title">
						Company
					</div>
					<div className="menu-item clickable">About Us</div>
					<div className="menu-item clickable">Privacy Policy</div>
					<div className="menu-item clickable">Contact Us</div>
				</div>
				<div className="item">
					<div className="menu-title">
						Social Links
					</div>
					<div className="menu-item clickable">Facebook</div>
					<div className="menu-item clickable">Instagram</div>
					<div className="menu-item clickable">Pinterest</div>
				</div>
			</div>
			<div className="divider" />
			<div className="copyright">&copy; 2021 MonkMerch Pvt Ltd. | All Rights Reserved</div>
		</div>
	)
}

export default Footer;